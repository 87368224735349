import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faStar, faCog, faFlag, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './MenuPopup.css';

function MenuPopup({ onClose }) {
  const [view, setView] = useState('main'); // State to track current view (main or sub-category)
  const [quality, setQuality] = useState('Auto');
  const [preferences, setPreferences] = useState({
    Hetero: true,
    Lesbians: true,
    Transexuelle: false,
    Gays: false,
  });

  const handleQualityChange = (e) => {
    setQuality(e.target.value);
  };

  const handlePreferenceChange = (e) => {
    setPreferences({
      ...preferences,
      [e.target.name]: e.target.checked,
    });
  };

  const renderMainView = () => (
    <div className="menu-content">
      <button onClick={() => setView('favourite')}>
        <FontAwesomeIcon icon={faStar} size="lg" /> Add to favourite
      </button>
      <button onClick={() => setView('quality')}>
        <FontAwesomeIcon icon={faCog} size="lg" /> Change quality
      </button>
      <button onClick={() => setView('preference')}>
        <FontAwesomeIcon icon={faCog} size="lg" /> Change preference
      </button>
      <button onClick={() => setView('report')}>
        <FontAwesomeIcon icon={faFlag} size="lg" /> Report
      </button>
    </div>
  );

  const renderQualityView = () => (
    <div className="menu-content">
      <button className="back-button" onClick={() => setView('main')}>
        <FontAwesomeIcon icon={faArrowLeft} size="lg" /> Back
      </button>
      <p>Change quality</p>
      {['Auto', '360p', '480p', '720p', '1080p'].map((q) => (
        <div key={q}>
          <input
            type="radio"
            id={q}
            name="quality"
            value={q}
            checked={quality === q}
            onChange={handleQualityChange}
          />
          <label htmlFor={q}>{q}</label>
        </div>
      ))}
    </div>
  );

  const renderPreferenceView = () => (
    <div className="menu-content">
      <button className="back-button" onClick={() => setView('main')}>
        <FontAwesomeIcon icon={faArrowLeft} size="lg" /> Back
      </button>
      <p>Change preference</p>
      {['Hetero', 'Lesbians', 'Transexuelle', 'Gays'].map((pref) => (
        <div key={pref}>
          <input
            type="checkbox"
            id={pref}
            name={pref}
            checked={preferences[pref]}
            onChange={handlePreferenceChange}
          />
          <label htmlFor={pref}>{pref}</label>
        </div>
      ))}
    </div>
  );

  const renderReportView = () => (
    <div className="menu-content">
      <button className="back-button" onClick={() => setView('main')}>
        <FontAwesomeIcon icon={faArrowLeft} size="lg" /> Back
      </button>
      <button>Report</button>
    </div>
  );

  const renderView = () => {
    switch (view) {
      case 'quality':
        return renderQualityView();
      case 'preference':
        return renderPreferenceView();
      case 'report':
        return renderReportView();
      default:
        return renderMainView();
    }
  };

  return (
    <div className="menu-popup open">
      <button className="close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </button>
      {renderView()}
    </div>
  );
}

export default MenuPopup;
