import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';
import './SharePopup.css';

function SharePopup({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);
  const link = "feeds.io/v249398";

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  };

  return (
    <div className={`share-popup ${isVisible ? 'open' : ''}`}>
      <button className="close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="share-content">
        <p>Share this link:</p>
        <div className="link-container">
          <input type="text" id="share-link" name="share-link" value={link} readOnly />
          <button className="copy-button" onClick={copyToClipboard}>
            <FontAwesomeIcon icon={faCopy} /> 
          </button>
        </div>
      </div>
    </div>
  );
}

export default SharePopup;
