import React from 'react';
import { NavLink } from 'react-router-dom';

const BottomNav = () => {
  return (
    <div className="bottom-nav">
      <NavLink to="/" exact className="nav-link" activeClassName="active">
        <img src="/images/bottom/home.svg" className="nav-icon" alt="Home" /> {/* Icon for Home */}
      </NavLink>

      <NavLink to="/discover" className="nav-link" activeClassName="active">
        <img src="/images/bottom/search.svg" className="nav-icon" alt="Search" /> {/* Icon for Search */}
      </NavLink>
  
      <NavLink to="/apply" className="nav-link" activeClassName="active">
        <img src="/images/bottom/plus.svg" className="nav-icon" alt="Apply" /> {/* Icon for Apply */}
      </NavLink>

      <NavLink to="/message" className="nav-link" activeClassName="active">
        <img src="/images/bottom/message.svg" className="nav-icon" alt="Apply" /> {/* Icon for Apply */}
      </NavLink>

      <NavLink to="/profileUser" className="nav-link" activeClassName="active">
        <img src="/images/bottom/profil.svg" className="nav-icon" alt="Profile" /> {/* Icon for Profile */}
      </NavLink>
    </div>
  );
};

export default BottomNav;
