import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faVolumeMute, faVolumeUp,faChevronDown, faShare, faEllipsisH, faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import './VideoPage3.css';
import SharePopup from './SharePopup'; // Assurez-vous que ces composants existent ou ajustez selon vos besoins
import MenuPopup from './MenuPopup';

const VideoPage3 = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  const toggleFollow = () => {
    setIsFollowing(!isFollowing);
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  const toggleSharePopup = () => {
    setShowSharePopup(!showSharePopup);
  };

  const toggleMenuPopup = () => {
    setShowMenuPopup(!showMenuPopup);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  

  return (
    <div className="flex flex-col flex-grow w-full mx-auto" style={{ paddingBottom: 'calc(50px + env(safe-area-inset-bottom))' }}>
      <div className="relative flex flex-col flex-grow items-center overflow-hidden">
        <div className="relative w-full flex-grow touch-none overflow-hidden flex justify-center items-center">
          <div className="absolute top-0 bottom-0 left-0 right-0 will-change-transform" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
            <div className="relative h-full w-full flex m-auto bg-center bg-cover overflow-hidden justify-center rounded-md" style={{ borderTop: '1px solid black' }}>
              <div className="relative w-full md:w-auto md:aspect-mobile">
                <div className="absolute top-0 bottom-0 left-0 right-0 select-none">
                  <video 
                    ref={videoRef}
                    controlsList="nodownload" 
                    className="h-full w-full object-cover object-center pointer-events-none rounded-md" 
                    preload="auto" 
                    playsInline 
                    loop 
                    src="/videos/video3.mp4"
                    muted={isMuted}
                  ></video>
                  <div className="absolute bottom-0 left-0 right-0 flex justify-between items-center p-4 text-white pointer-events-auto z-10">
                    <div className="profile flex items-center">
                      
                      <div className="ml-2">
                        <div 
                          className="username-feed cursor-pointer" 
                          onClick={goToProfile}
                        >
                          CAPRICHOCARLA
                          <div className="image-container2">
                            <img src="/images/feed/verifiedProfile.svg" alt="Profile" className="profile-verified" />
                            <img src="/images/feed/verifiedProfileCross.svg" alt="Profile" className="profile-cross" />
                          </div>
                        </div>
                     
                      </div>
                    </div>
                    <div className="video-actions flex flex-col items-center">

                    <button className="action-button">
                    <div className="image-container" onClick={goToProfile}>

                        <img src="/images/image1.webp" alt="Profile" className="profile-image" />
                        <img src="/images/feed/plusProfile.svg" alt="Plus Profile" className="plus-profile" />
                    </div>
                    </button>
                      

                      <button className="action-button mb-2" onClick={toggleLike} style={{ color: isLiked ? 'red' : 'white' }}>
                      <img src="/images/feed/heart.svg" className="menu-icon" alt="Heart" />
                        <span>{isLiked ? '4.4K' : ''}</span>
                      </button>
                      <button className="action-button mb-2" style={{ height: '30px',width: '30px' }} onClick={toggleMute}>
                        <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                      </button>
                      <button className="action-button mb-2" onClick={toggleSharePopup}>
                        <img src="/images/feed/share.svg" className="menu-icon" alt="Heart" />
                      </button>
                      <button className="action-button" onClick={toggleMenuPopup}>
                        <img src="/images/feed/dots.svg"  className="menu-icon" alt="Dots" />
                      </button>
                    </div>
                  </div>
                  <div className="video-details p-4 text-white pointer-events-auto z-10">
                    <div className="caption">Titty drop to start your day;))</div>
                    <div className="hashtags-container">
                      <div className="hashtags">#boobs</div>
                      <div className="hashtags">#ass</div>
                    </div>
                  </div>

                  <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center pointer-events-none">
          <div className="absolute top-0 left-0 right-0 h-20 bg-gradient-to-b from-black opacity-20 pointer-events-none"></div>
          <div className="relative h-full w-full md:w-auto md:aspect-mobile flex justify-center pointer-events-none">
      <div className="absolute left-4 top-4 w-6 h-auto text-white select-none"></div>
      <div className="absolute top-4 right-4 size-7 pointer-events-auto select-none sidebar-position drop-shadow">
      <button onClick={toggleDropdown} className="bg-transparent text-white px-3 py-2 rounded-md shadow-md flex items-center" style={{ fontSize: '24px', fontFamily: 'Inter',fontWeight: 'bold',
    color: 'white' }}>
                          For You
                          <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
        </button>
        {dropdownOpen && (
          <div className="absolute top-12 right-0 rounded-md bg-white overflow-hidden pointer-events-auto text-sm min-w-32 shadow-lg transition-opacity mt-2 opacity-100 visible" style={{ fontSize: '0.9375rem', lineHeight: '0.9375rem' }}>
            <a className="flex gap-3 px-3 py-2 text-black select-none" href="/new">
              <div className="size-4"></div>
              <div>New</div>
            </a>
            <a className="flex gap-3 px-3 py-2 text-black select-none" href="/following">
              <div className="size-4"></div>
              <div>Following</div>
            </a>
          </div>
        )}
      </div>
    </div>
        </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {showSharePopup && <SharePopup onClose={toggleSharePopup} />}
      {showMenuPopup && <MenuPopup onClose={toggleMenuPopup} />}
    </div>
  );
};

export default VideoPage3;
