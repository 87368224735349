import React from 'react';

const ProfileHeader = ({ username, profilePic, followers, clips, views }) => {
  return (
    <div className="profile-header">
      
      <img src={profilePic} alt="Profile" className="profile-pic" />
      <div className="stats">
        <div className="stat">
          <span className="stat-count">{clips}</span>
          <span className="stat-label">Clips</span>
        </div>
        <div className="stat">
          <span className="stat-count">{followers}</span>
          <span className="stat-label">Followers</span>
        </div>
        <div className="stat">
          <span className="stat-count">{views}</span>
          <span className="stat-label">Views</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
