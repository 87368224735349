import React from 'react';

function HashtagGallery({ hashtag, images, count }) {
  // This function could be used to handle clicking on the hashtag
  const handleHashtagClick = () => {
    console.log(`Navigate to page with hashtag: ${hashtag}`);
    // Example: navigate to /hashtag/${hashtag}
  };

  return (
    <div className="hashtag-gallery">
      <div className="hashtag-header" onClick={handleHashtagClick}>
        <span className="hashtag-header">{hashtag}</span>
        <span className="hashtag-count">{count}</span>
      </div>
      <div className="hashtag-images">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Post related to ${hashtag}`} />
        ))}
      </div>
    </div>
  );
}

export default HashtagGallery;
