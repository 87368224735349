import React, { useState } from 'react';
import Modal from 'react-modal';
import './ProfilUserPage.css'; // Ensure CSS is correctly set up for this structure
import { ReactComponent as ProfilePic } from '../anonymProfil.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faHandshake, faInfoCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root'); // Set the root element for accessibility

function ProfileUserPage() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const openModal = (login) => {
    setIsLogin(login);
    setModalIsOpen(true);
  };
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="profile-page">
      <div className="top-section">
        <ProfileHeader username="Anonymous" />
        <div className="button-container">
          <Button text="Sign Up" onClick={() => openModal(false)} />
          <Button text="Log In" onClick={() => openModal(true)} />
        </div>
      </div>
      <div className="settings-section">
        <div className="settings-option">
          <span>Sexual Preferences</span>
          <FontAwesomeIcon icon={faChevronRight} className="settings-icon" />
        </div>
        <div className="settings-option">
          <span>Playback Settings</span>
          <FontAwesomeIcon icon={faChevronRight} className="settings-icon" />
        </div>
      </div>
      <div className="become-creator-container">
        <Button text="Become Creator" className="become-creator-button" />
      </div>
      <div className="bottom-section">
        <div className="footer-container">
          <FooterButton icon={faDownload} text={["App", "Download"]} />
          <FooterButton icon={faHandshake} text={["Become", "Partner"]} />
          <FooterButton icon={faInfoCircle} text={["About", "FikFap"]} />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Authentication Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{isLogin ? 'Login' : 'Join the community'}</h2>
        <form>
          <label>Email</label>
          <input type="email" required />
          <label>Password</label>
          <input type="password" required />
          <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
        </form>
        <button onClick={closeModal} className="close-button">Close</button>
      </Modal>
    </div>
  );
}

function ProfileHeader({ username }) {
  return (
    <div className="profile-header">
      <ProfilePic className="profile-pic" />
      <span className="username">{username}</span>
    </div>
  );
}

function Button({ text, onClick, className }) {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {text}
    </button>
  );
}

function FooterButton({ icon, text }) {
  return (
    <button className="footer-button">
      <FontAwesomeIcon icon={icon} className="footer-icon" />
      <div className="footer-text">
        {text.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </button>
  );
}

export default ProfileUserPage;
