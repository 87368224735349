import React from 'react';
import ProfileHeader from '../components/ProfileHeader';
import Button from '../components/Button';
import '../App.css'; // Ensure CSS is correctly set up for this structure

function ProfilePage() {
  // Array of image filenames
  const imageFilenames = ['image1.webp', 'image2.webp', 'image3.webp', 'image4.webp', 'image5.webp', 'image6.webp', 'image4.webp', 'image5.webp', 'image6.webp'];

  return (
    <div className="profile-page">
       <div className="username-container">
      <span className="username">@zoeneli</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.87" height="1.5%" width="1.5%"><polygon fill="blue" points="61.37 8.24 80.43 0 90.88 17.79 111.15 22.32 109.15 42.85 122.88 58.43 109.2 73.87 111.15 94.55 91 99 80.43 116.87 61.51 108.62 42.45 116.87 32 99.08 11.73 94.55 13.73 74.01 0 58.43 13.68 42.99 11.73 22.32 31.88 17.87 42.45 0 61.37 8.24 61.37 8.24"></polygon><path fill="#fff" d="M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z"></path></svg>
    </div>
      <div className="top-section">
        <ProfileHeader
          username="@zoeneli"
          profilePic="/images/profile.webp"
          followers="62.4K"
          views="42.5M"
          clips="76"
        />
        <div className="button-container">
          <Button text="Follow" />
          <Button text="Subscribe" />
        </div>
      </div>
      <div className="media-section">
        {imageFilenames.map((filename, index) => (
          <div key={index} className="media-box">
            <img src={`/images/${filename}`} alt={`Media ${index}`} className="media-image" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfilePage;
