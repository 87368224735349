import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProfilePage from './pages/ProfilePage'; // Adjusted import to use the new Profile Page
import VideoPage from './pages/VideoPage3';
import BottomNav from './components/BottomNav';
import HomePage from './pages/HomePage'; 
import DiscoverPage from './pages/DiscoverPage';
import ProfilUserPage from './pages/ProfilUserPage'
import ApplyPage from './pages/ApplyCreator'
import './App.css';

function Main() {
  return (
    <Router>
      <div className="main-app">
        <Routes>
          <Route path="/" element={<VideoPage />} exact />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profileUser" element={<ProfilUserPage />} />
          <Route path="/discover" element={<DiscoverPage />} />
          <Route path="/apply" element={<ApplyPage />} />
          {/* Define other routes here */}
        </Routes>
        <BottomNav />
      </div>
    </Router>
  );
}

export default Main;
