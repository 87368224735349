import React from 'react';
import './ApplyCreator.css'; // Ensure CSS is correctly set up for this structure

function ApplyCreator() {
  return (
    <div className="apply-creator">
      <h1>Partners</h1>
      <div className="content">
        <h2>How to upload videos?</h2>
        <p>
          To be able to upload videos you need to become a Partner. As a Partner, you will be able 
          to reach FikFap's audience of over 20 million monthly users and get clicks to your OnlyFans, 
          Fansly, Linktree, Twitter, or any site you want!
        </p>
        <p>Combined, our Partners receive:</p>
        <div className="statistics">
          <span className="large-text">107K</span>
          <p>Clicks to their links per day</p>
        </div>
        <p>Join hundreds of other Creators such as:</p>
        <div className="creators">
          <p>Your Submissive Doll</p>
          <p>Natural Nadia</p>
          <p>Ginger4Play</p>
        </div>
        <button className="apply-button">Apply</button>
      </div>
    </div>
  );
}

export default ApplyCreator;
