import React from 'react';
import './DiscoverPage.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import  HashtagGallery  from '../components/HashtagGallery';

function DiscoverPage() {
  // Example data, replace with real data fetches or props
  const creators = [
    { name: 'Creator 1', image: '../images/image1.webp' },
    { name: 'Creator 2', image: '../images/image2.webp' },
    { name: 'Creator 1', image: '../images/image3.webp' },
    { name: 'Creator 2', image: '../images/image4.webp' },
    { name: 'Creator 1', image: '../images/image5.webp' },
    { name: 'Creator 2', image: '../images/image2.webp' },
    { name: 'Creator 1', image: '../images/image3.webp' },
    { name: 'Creator 2', image: '../images/image4.webp' },
    { name: 'Creator 2', image: '../images/image2.webp' },
    { name: 'Creator 1', image: '../images/image1.webp' },
    { name: 'Creator 2', image: '../images/image2.webp' },
    { name: 'Creator 1', image: '../images/image3.webp' },
    { name: 'Creator 2', image: '../images/image4.webp' },
    { name: 'Creator 1', image: '../images/image5.webp' },
    { name: 'Creator 2', image: '../images/image2.webp' },
    { name: 'Creator 1', image: '../images/image3.webp' },
    { name: 'Creator 2', image: '../images/image4.webp' },
    { name: 'Creator 2', image: '../images/image2.webp' },

   
    // Add more creators
  ];

  const hashtags = [
    { tag: '#onoff',  image: '../images/image1.webp' },
    { tag: '#onoff',  image: '../images/image2.webp' },
    { tag: '#onoff',  image: '../images/image3.webp' },
    { tag: '#onoff',  image: '../images/image4.webp' },
    { tag: '#onoff',  image: '../images/image5.webp' },
    { tag: '#onoff',  image: '../images/image6.webp' },
    { tag: '#onoff',  image: '../images/image1.webp' },
    { tag: '#onoff',  image: '../images/image1.webp' },
    { tag: '#onoff',  image: '../images/image3.webp' },
    { tag: '#onoff',  image: '../images/image4.webp' },
    { tag: '#onoff',  image: '../images/image5.webp' },
    { tag: '#onoff',  image: '../images/image6.webp' },
    { tag: '#onoff',  image: '../images/image1.webp' },
    { tag: '#onoff',  image: '../images/image1.webp' },
    { tag: '#onoff',  image: '../images/image4.webp' },
    { tag: '#onoff',  image: '../images/image5.webp' },
    { tag: '#onoff',  image: '../images/image6.webp' },
    { tag: '#onoff',  image: '../images/image1.webp' },
    { tag: '#onoff',  image: '../images/image1.webp' },
   
  ];

  const hashtagsInfo = [
    {
      hashtag: '#latino',
      count: 4,
      images: [
        '../images/image1.webp',
        '../images/image2.webp',
        '../images/image3.webp',
        '../images/image4.webp'
      ]
    },
    {
      hashtag: '#sweden',
      count: 6,
      images: [
        '../images/image1.webp',
        '../images/image2.webp',
        '../images/image3.webp',
        '../images/image4.webp',
        '../images/image2.webp',
        '../images/image3.webp'
      ]
    },
    {
      hashtag: '#random',
      count: 2,
      images: [
        '../images/image1.webp',
        '../images/image2.webp'
      ]
    },
    {
      hashtag: '#wife',
      count: 7,
      images: [
        '../images/image1.webp',
        '../images/image2.webp',
        '../images/image3.webp',
        '../images/image4.webp',
        '../images/image2.webp',
        '../images/image3.webp',
        '../images/image1.webp'
      ]
    },
    // Additional hashtags as shown above
  ];

  const handleHashtagClick = (hashtag) => {
    console.log("Clicked on Hashtag: ", hashtag);
    // You can navigate to a different route or perform any action
  };

  return (
    <div className="discover-page">
       <div className="discover-up">
       <h1 className="discover-title">Discover</h1> {/* Title for the page */}
      <div className="search-section">
        <input type="text" placeholder="Type something..." />
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
      </div>
     </div>
      <div className="creators-we-think-youll-like">
        <h3>Creators we think you'll like</h3>
        <div className="creator-list">
          {creators.map((creator, index) => (
            <div key={index} className="creator">
              <img src={creator.image} alt={creator.name} />
            </div>
          ))}
        </div>
      </div>
      <div className="hashtags-we-think-youll-like">
        <h3>Hashtags we think you'll like</h3>
        <div className="hashtag-list">
          {hashtags.map((hashtag, index) => (
            <div key={index} className="hashtag">
              <img src={hashtag.image} alt={hashtag.tag} />
              <div className="hashtag-info">
                <span className="hashtag-text">{hashtag.tag}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="creators-we-think-youll-like">
        <h3>New Creators that joined this week</h3>
        <div className="creator-list">
          {creators.map((creator, index) => (
            <div key={index} className="creator">
              <img src={creator.image} alt={creator.name} />
            </div>
          ))}
        </div>
      </div>
      <div className="creators-we-think-youll-like">
        <h2>Top Creators this week</h2>
        <div className="creator-list">
          {creators.map((creator, index) => (
            <div key={index} className="creator">
              <img src={creator.image} alt={creator.name} />
            </div>
          ))}
        </div>
      </div>
      <div className="hashtags-we-think-youll-like">
        <h3>Hashtags we think you'll like</h3>
        <div className="hashtag-list">
          {hashtags.map((hashtag, index) => (
            <div key={index} className="hashtag">
              <img src={hashtag.image} alt={hashtag.tag} />
              <div className="hashtag-info">
                <span className="hashtag-text">{hashtag.tag}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="hashtags-we-think-youll-like">
    
      {hashtagsInfo.map((hashtag, index) => (
        <HashtagGallery 
          key={index}
          hashtag={hashtag.hashtag}
          images={hashtag.images}
          count={hashtag.count}
        />
      ))}
    </div>
    </div>
  );
}

export default DiscoverPage;
